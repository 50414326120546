@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-color: #3751fe;
  --light-blue: #DDE6F0;
  --dark-blue: #2D49C8;
}

/* Global Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: #3751fe;
  line-height: 1.6;
  background-color: #F0F4F8;
  overflow-x: hidden;
}

/* Header */
header {
  background: #3751fe;
  padding: 1.5rem 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links ul {
  display: flex;
  list-style: none;
  margin-right: 1.5rem;
}

.nav-links ul li {
  margin: 0 1rem;
  transition: all 0.3s ease;
  color: white;
}

.nav-links ul li a {
  color: #F0F4F8 !important;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.logo {
  font-size: 1.8rem;
  color: #FFF;
  font-weight: 700;
}

/* CTA Button */
.cta-button {
  background: #FFF;
  color: #3751fe;
  padding: 0.6rem 1.5rem;
  border-radius: 50px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.nav-links ul li a:hover,
.cta-button:hover {
  color: #DDE6F0;
}

.cta-button:hover {
  background-color: #DDE6F0;
  color: #3751fe;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.hero {
  background: url('https://media.istockphoto.com/id/1377893181/photo/shot-of-young-man-delivering-a-package-while-sitting-in-a-vehicle.jpg?s=612x612&w=0&k=20&c=yOMqI9TcSFRPKuLl40lUsjYmRkji9hoH_eUtKPUrZwk=') center/cover no-repeat;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  padding: 0 5%;
}

.hero-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-content p {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto 2rem;
}

/* Button Group */
.button-group {
  display: flex;
  flex-direction: column; /* Change to row for horizontal layout */
  gap: 1rem; /* Space between buttons */
  align-items: center; /* Center the buttons */
}

/* Styled Buttons */
.hero-button,
.app-button {
  background: #3751fe; /* Same background color */
  color: #FFF; /* White text */
  padding: 0.8rem 2rem; /* Consistent padding */
  border-radius: 50px; /* Rounded corners */
  font-weight: 600;
  text-decoration: none; /* Remove underline */
  transition: all 0.3s ease; /* Smooth transition */
  display: inline-block; /* Ensure they behave like buttons */
}

.hero-button:hover,
.app-button:hover {
  background-color: #2D49C8; /* Darker on hover */
}

.app-button img {
  height: 50px; /* Ensure the Play Store badge is responsive */
  width: auto; /* Keep aspect ratio */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-content h2 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .hero-button,
  .app-button img {
    width: 100%; /* Full width on smaller screens */
  }
}

.hero h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto 2rem;
}

.hero-button {
  background: #FFF;
  color: #3751fe;
  padding: 0.8rem 2rem;
  border-radius: 50px;
  font-weight: 600;
  transition: transform 0.3s ease;
}

.hero-button:hover {
  transform: scale(1.05);
  background: #DDE6F0;
  color: #3751fe;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

/* Hero Section - App Button */
.hero .app-button {
  display: inline-block;
  margin-top: 1.5rem;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.hero .app-button img {
  height: 50px;
  width: auto;
}

.hero .app-button:hover {
  transform: scale(1.05);
}



/* How It Works Section */
.how-it-works {
  padding: 3rem 0; /* Set padding for top and bottom, no side padding */
  background-color: #F0F4F8;
  width: 100%; /* Ensure full width */
}

.how-it-works h2 {
  font-size: 2rem;
  color: #3751fe;
  margin-bottom: 2rem;
}

.steps {
  display: flex;
  justify-content: space-around;
  gap: 1.5rem;
  width: 100%; /* Ensure it takes full width */
}

.step {
  flex: 1;
  background: #FFF;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.step:hover {
  transform: translateY(-5px);
}

/* Features Section */
.features {
  padding: 3rem 5%;
  background-color: #FFF;
}

.features h2 {
  font-size: 2rem;
  color: #3751fe;
  margin-bottom: 2rem;
}

.feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1.5rem;
}

.feature {
  background: #F9FAFB;
  padding: 2rem;
  border-radius: 10px;
  flex: 1;
  min-width: 250px;
  text-align: center;
  transition: transform 0.3s ease;
}

.feature:hover {
  transform: translateY(-5px);
}

/* Pricing Section */
.pricing {
  padding: 3rem 0; /* Set padding for top and bottom, no side padding */
  background-color: #F0F4F8;
  width: 100%; /* Ensure full width */
}

.pricing h2 {
  font-size: 2rem;
  color: #3751fe;
  margin-bottom: 2rem;
}

.pricing-options {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  width: 100%; /* Ensure it takes full width */
}

.price-option {
  background: #FFF;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1;
  max-width: 600px;
  transition: transform 0.3s ease;
}

.price-option:hover {
  transform: translateY(-5px);
}

/* Footer */
footer {
  background: #3751fe;
  color: #FFF;
  text-align: center;
  padding: 2rem 5%;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-links {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.footer-links li a {
  color: #FFF;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links li a:hover {
  color: #DDE6F0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .pricing-options,
  .steps {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }

  .pricing-options .price-option,
  .steps .step {
    max-width: 90%; /* Allow more width on small screens */
  }
}


/* src/styles.css */
@keyframes shape1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shape2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shape3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shape4 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shape5 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shape6 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shape7 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shape8 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-shape1 {
  animation: shape1 5s ease-in-out infinite;
}

.animate-shape2 {
  animation: shape2 7s ease-in-out infinite;
}

.animate-shape3 {
  animation: shape3 6s ease-in-out infinite;
}

.animate-shape4 {
  animation: shape4 4s ease-in-out infinite;
}

.animate-shape5 {
  animation: shape5 6s ease-in-out infinite;
}

.animate-shape6 {
  animation: shape6 5s ease-in-out infinite;
}

.animate-shape7 {
  animation: shape7 8s ease-in-out infinite;
}

.animate-shape8 {
  animation: shape8 7s ease-in-out infinite;
}

.text-dark-blue {
  color: #1a237e;
}

.bg-dark-blue {
  background-color: #1a237e;
}


.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
